import { Avatar, Select } from "antd";
import React, { useEffect, useState } from "react";
const { Option } = Select;

const CashboxUsersAutoComplete = (props) => {
  const [options, setOptions] = useState([]);
  const [val, setVal] = useState("");

  const fetchUsers = async (name) => {
    return fetch(
      `https://${process.env.REACT_APP_APP_URL}/api/v1/cashbox_users/?token=${props.token}&name=${name}`
    )
      .then((response) => response.json())
      .then((body) => {
        if (body.result) {
          setOptions(
            body.result.map((user) => ({
              label: `${user.first_name} ${
                user.last_name ? user.last_name : ""
              }`,
              value: user.id,
              avatar: `https://${process.env.REACT_APP_APP_URL}/${user.photo}`,
            }))
          );
        }
        return [];
      });
  };

  useEffect(() => {
    fetchUsers("");
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchUsers(val);
    console.log(options);
    //eslint-disable-next-line
  }, [val]);

  return (
    <Select
      {...props}
      allowClear
      showSearch
      suffixIcon={null}
      onChange={(val) => setVal(val)}
      filterOption={false}
      onSearch={(newVal) => setVal(newVal)}
      notFoundContent={null}
    >
      {options.map((option) => (
        <Option key={option.value} value={option.value} avatar={option.avatar}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={option.avatar}
              size={"small"}
              style={{ marginRight: 5 }}
            />
            {option.label}
          </div>
        </Option>
      ))}
    </Select>
  );
};

export default CashboxUsersAutoComplete;

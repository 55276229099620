import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Select, Typography } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";

const DocSalesAutoComplete = (props) => {
  const [options, setOptions] = useState([]);

  const fetchDocsSales = async (id) => {
    axios
      .get(
        `https://${process.env.REACT_APP_APP_URL}/api/v1/docs_sales/${id}/?token=${props.token}`
      )
      .then((response) => {
        if (response.data.result) {
          setOptions(
            response.data.result.map((item) => ({
              label: item.number,
              value: item.id,
              tags: item.tags,
              contragent: item.contragent_name,
              sum: item.sum,
              full_paid: item.paid_doc >= item.sum - item.doc_discount,
            }))
          );
        } else if (response.data) {
          const item = response.data;
          setOptions([
            {
              label: item.number,
              value: item.id,
              tags: item.tags,
              contragent: item.contragent_name,
              sum: item.sum,
              full_paid: item.paid_doc >= item.sum - item.doc_discount,
            },
          ]);
        }
      });
  };

  useEffect(() => {
    fetchDocsSales("");
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.isNewDocSales) {
      fetchDocsSales("");
      props.setIsNewDocSales(false);
    }
    //eslint-disable-next-line
  }, [props.isNewDocSales]);

  return (
    <Select
      onSelect={props.select}
      allowClear
      // showSearch
      // suffixIcon={null}
      onChange={(val) => {
        // fetchDocsSales(val);
        props.setDocSalesId(val);
      }}
      filterOption={false}
      value={props.docSalesId}
      onSearch={(val) => {
        // fetchDocsSales(val);
        props.setDocSalesId(val);
      }}
      onClear={() => {
        // fetchDocsSales("");
        props.setDocSalesId("");
      }}
      notFoundContent={null}
      // optionLabelProp="value"
      options={options}
      optionRender={(option) => (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "0.5fr 1fr 1.5fr 1fr 0.4fr",
            gap: 0,
          }}
          align="center"
        >
          {/* <Typography.Text style={{ textAlign: "left" }}>
            {option.data.value}
          </Typography.Text> */}
          <Typography.Text>{option.data.label}</Typography.Text>
          <Typography.Text>
            {option.data.tags ? option.data.tags : "-"}
          </Typography.Text>
          <Typography.Text>
            {option.data.contragent ? option.data.contragent : "-"}
          </Typography.Text>
          <Typography.Text>
            {option.data.sum ? option.data.sum : "-"}
          </Typography.Text>
          <Typography.Text style={{ alignItems: "center" }}>
            {option.data.full_paid ? (
              <font style={{ color: "green" }}>
                <CheckCircleOutlined />
              </font>
            ) : (
              <font style={{ color: "red" }}>
                <CloseCircleOutlined />
              </font>
            )}
          </Typography.Text>
        </div>
      )}
      dropdownRender={(menu) => (
        <>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "0.5fr 1fr 1.4fr 1fr 0.4fr",
              gap: 0,
              padding: "0 12px",
            }}
            align="center"
          >
            {/* <Typography.Text
              type="secondary"
              style={{ flex: "10%", textAlign: "left", fontSize: "12px" }}
            >
              Id
            </Typography.Text> */}
            <Typography.Text style={{ fontSize: "12px" }} type="secondary">
              Номер
            </Typography.Text>
            <Typography.Text style={{ fontSize: "12px" }} type="secondary">
              Теги
            </Typography.Text>
            <Typography.Text style={{ fontSize: "12px" }} type="secondary">
              Контрагент
            </Typography.Text>
            <Typography.Text style={{ fontSize: "12px" }} type="secondary">
              Сумма
            </Typography.Text>
            <Typography.Text style={{ fontSize: "12px" }} type="secondary">
              Статус
            </Typography.Text>
          </div>

          {menu}
        </>
      )}
    />
  );
};

export default DocSalesAutoComplete;

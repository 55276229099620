// модальное окно на странице "Документы продаж"
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  message,
  Layout,
  InputNumber,
  Table,
  Popconfirm,
  Popover,
  Select,
  Checkbox,
  DatePicker,
} from "antd";
import ContragentAutocomplete from "src/shared/api/ContragentAutocomplete";

import {
  DollarOutlined,
  DeleteOutlined,
  EditOutlined,
  SettingOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { NomenclatureAutoComplete } from "src/shared";
import { WareHousesAutocomplete } from "src/shared";
import { OrganizationAutoComplete } from "src/shared";
import { apiRequests } from "src/shared/api/api";
import BarcodeReader from "react-barcode-reader";
import axios from "axios";
import { AddBarCode } from "..";
import NomenclatureSearchField from "src/shared/api/NomenclatureSearchField";
import Compact from "antd/es/space/Compact";
import { DocSalesDrawer } from "src/enitities/Form";

const searchResult = async (api, token, query, ts) => {
  let type = ts ? "barcode" : "name";
  return fetch(`${api}nomenclature/?token=${token}&${type}=${query}`)
    .then((response) => response.json())
    .then((body) =>
      body.result.map((user) => ({
        label: `${user.name}`,
        value: user.id,
      }))
    );
};

const { Header, Sider, Content } = Layout;

const headerStyle = {
  textAlign: "center",
  color: "#fff",
  height: 35,
  backgroundColor: "white",
  width: "100%",
  padding: "0",
};
const contentStyle = {
  textAlign: "center",
  lineHeight: "120px",
  color: "#fff",
  backgroundColor: "white",
};
const siderStyle = {
  textAlign: "center",
  backgroundColor: "white",
};

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  const calculateSum = () => {
    const valueInput = form.getFieldValue(dataIndex);
    let sum = Number(record.amount) * (Number(valueInput) / 100);
    form.setFieldValue(dataIndex, sum.toFixed(2));
  };

  const handleBlur = (e) => {
    if (e.relatedTarget.type === "button") {
      calculateSum();
      save();
    } else {
      save();
    }
  };

  let childNode = children;

  let ed = editing ? (
    <Form.Item
      style={{
        margin: 0,
      }}
      name={dataIndex}
      rules={[
        {
          required: dataIndex !== "amount",
          message: `${title} обязательно для ввода.`,
        },
      ]}
    >
      <Input
        ref={inputRef}
        addonAfter={
          dataIndex === "discount" ? (
            <Button type={"text"} size={"small"} icon={<DollarOutlined />} />
          ) : null
        }
        onPressEnter={save}
        onBlur={handleBlur}
      />
    </Form.Item>
  ) : (
    <div
      className="editable-cell-value-wrap"
      style={{
        paddingRight: 24,
      }}
      onClick={toggleEdit}
    >
      {children}
    </div>
  );

  if (editable) {
    if (record.type !== "transfer") {
      childNode = ed;
    } else {
      if (dataIndex === "tax") {
        childNode = null;
      } else {
        childNode = ed;
      }
    }
  }

  return <td {...restProps}>{childNode}</td>;
};

class EditDocsSales extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.formRefSettings = React.createRef();
    this.formRefNom = React.createRef();
    this.finRef = React.createRef();

    this.state = {
      current_contragent: null,
      current_nomenclature: null,
      changeData: false,
      discount_type: "percent",
      loyality_balance: 0,
      nomenclature_min: 0,
      nomenclature_max: 0,
      amount_without_discount: 0,
      amount_with_discount: 0,
      amount_discount: 0,
      nomDS: [],
      current_nomenclature_price: null,
      nomId: null,
      isRequestLoading: false,
      isAddBarCodeVisible: false,
      barcode: null,
      orgData: [],
      current_organization: {},
    };
    this.api = `https://${process.env.REACT_APP_APP_URL}/api/v1/`;

    this.leftBarIV = {
      number: this.props.doc.number,
      comment: this.props.doc.comment,
    };

    this.columns = [
      {
        title: "Название товара",
        key: "name",
        dataIndex: "name",
      },
      {
        title: "Сумма",
        key: "amount",
        dataIndex: "amount",
        editable: true,
      },
      {
        title: "Скидка",
        key: "discount",
        dataIndex: "discount",
        editable: true,
      },
      {
        title: "Количество",
        key: "count",
        dataIndex: "count",
        editable: true,
      },
      {
        title: "Единица",
        key: "unit",
        dataIndex: "unit",
      },
      {
        title: "Итого",
        key: "final_amount",
        dataIndex: "final_amount",
      },
      {
        title: "Действие",
        key: "action",
        dataIndex: "action",
        render: (_, record) => {
          return (
            <Popconfirm
              title="Подтвердите удаление"
              onConfirm={() => this.handleDeleteNom(record.id)}
              cancelText="Отмена"
              okText="OK"
            >
              <Button icon={<DeleteOutlined />} style={{ marginRight: 10 }} />
            </Popconfirm>
          );
        },
      },
    ];
  }

  closeAddBarcode = () => {
    this.setState({ isAddBarCodeVisible: false, barcode: null });
  };

  barcodeFind = async (data) => {
    const res = await searchResult(this.api, this.props.token, data, true);
    if (!res[0]) {
      this.setState({ isAddBarCodeVisible: true, barcode: data });
    } else {
      this.onSelectNom(res[0].value);
    }
    console.log(res);
  };

  findDocSales = async (id) => {
    return fetch(
      `https://${process.env.REACT_APP_APP_URL}/api/v1/docs_sales/${id}/?token=${this.props.token}`
    )
      .then((response) => response.json())
      .then((body) => {
        return body;
      });
  };

  findContragent = async (id) => {
    return fetch(
      `https://${process.env.REACT_APP_APP_URL}/api/v1/contragents/${id}/?token=${this.props.token}`
    )
      .then((response) => response.json())
      .then((body) => {
        return body;
      });
  };

  raschet = (nomDS) => {
    let sum_without_d = 0;
    let sum_with_d = 0;
    let sum_of_d = 0;

    nomDS.map((item) => {
      let discount = parseFloat(item.discount);
      let count = parseInt(item.count);
      let amount = parseFloat(item.amount);
      let final_amount = parseFloat(item.final_amount);

      sum_with_d += final_amount;
      sum_of_d += discount;
      sum_without_d += count * amount;
      return 1;
    });

    const amountWithoutDiscount = parseFloat(sum_without_d).toFixed(2);
    const amountDiscount = parseFloat(sum_of_d).toFixed(2);
    const amountWithDiscount = parseFloat(sum_with_d).toFixed(2);
    this.setFinishPrices(amountWithDiscount);
    this.setState({
      amount_without_discount: amountWithoutDiscount,
      amount_discount: amountDiscount,
      amount_with_discount: amountWithDiscount,
    });
  };

  handleSave = (row) => {
    const newData = [...this.state.nomDS];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];

    row.amount = parseFloat(row.amount).toFixed(2);
    row.discount = parseFloat(row.discount).toFixed(2);
    row.count = parseFloat(row.count).toFixed(3);
    row.final_amount = parseFloat(row.amount * row.count).toFixed(2);

    if (row.discount > 0) {
      row.final_amount = parseFloat(row.final_amount - row.discount).toFixed(2);
    }

    newData.splice(index, 1, { ...item, ...row });

    this.setState(
      {
        nomDS: newData,
      },
      () => this.raschet(newData)
    );

    // this.edit_request(newData.splice(index, 1, { ...item, ...row })[0]);
  };

  onSelectCa = (val) => {
    console.log(val);
    if (!val) {
      if (this.formRef.current) {
        this.formRef.current.setFieldsValue({
          contragent: null,
        });
      }
      return this.setState({ current_contragent: null });
    }
    this.findContragent(val).then((res) => {
      console.log(res);
      this.setState({ current_contragent: res });
      if (this.formRef.current) {
        this.formRef.current.setFieldsValue({
          contragent: res.name,
        });
      }

      fetch(
        `https://${process.env.REACT_APP_APP_URL}/api/v1/loyality_cards/?token=${this.props.token}&phone_number=${res.phone}`
      )
        .then((response) => response.json())
        .then((body) => {
          if (body.result.length === 0) {
            this.setState({
              loyality_balance: "Нет карты",
              loyality_card: null,
            });
          } else {
            this.setState(
              {
                loyality_card: body.result[0],
                loyality_balance: body.result[0].balance.toFixed(2),
              },
              () => this.setFinishPrices(this.state.amount_with_discount)
            );
          }
        });
    });
  };

  findContract = async (id) => {
    return fetch(
      `https://${process.env.REACT_APP_APP_URL}/api/v1/contracts/${
        id || ""
      }/?token=${this.props.token}`
    )
      .then((response) => response.json())
      .then((body) => {
        return body;
      });
  };

  onSelectContract = (val) => {
    this.findContract(val).then((res) => {
      this.setState({ current_contract: res });
      if (this.formRef.current) {
        this.formRef.current.setFieldsValue({
          contract: res.name,
        });
      }
    });
  };

  findOrg = async (id) => {
    return fetch(
      `https://${process.env.REACT_APP_APP_URL}/api/v1/organizations/${id}/?token=${this.props.token}`
    )
      .then((response) => response.json())
      .then((body) => {
        return body;
      });
  };

  onSelectOrg = (val) => {
    this.setState({ current_organization: val });
    this.formRef.current.setFieldValue("organization", val);
  };

  findWareHouse = async (id) => {
    return fetch(
      `https://${process.env.REACT_APP_APP_URL}/api/v1/warehouses/${id}/?token=${this.props.token}`
    )
      .then((response) => response.json())
      .then((body) => {
        return body;
      });
  };

  onSelectWareHouse = (val) => {
    this.findWareHouse(val).then((res) => {
      this.setState({ current_warehouse: res });
      if (this.formRef.current) {
        this.formRef.current.setFieldsValue({
          warehouse: res.name,
        });
      }
    });
  };

  findNomenclature = async (id) => {
    return fetch(
      `https://${process.env.REACT_APP_APP_URL}/api/v1/nomenclature/${id}/?token=${this.props.token}`
    )
      .then((response) => response.json())
      .then((body) => {
        return body;
      });
  };

  handleDeleteNom = (id) => {
    const newData = [...this.state.nomDS];
    const index = newData.findIndex((item) => id === item.id);

    if (index !== -1) {
      newData.splice(index, 1);
      this.setState(
        (prev) => ({ ...prev, nomDS: newData }),
        () => this.raschet(newData)
      );
    }
  };

  onSelectNom = (val) => {
    this.setState({ current_nomenclature_price: null });
    this.findNomenclature(val).then((res) => {
      this.setState({ current_nomenclature: res });
      this.setState({ nomId: res.id });
      this.formRefNom.current.setFieldsValue({
        nomenclature: res.name,
        chosen_nomenclature: res.name,
      });

      if (res.type === "product") {
        if (!this.state.current_warehouse) {
          message.error("Вы не выбрали склад!");
        } else {
          fetch(
            `https://${process.env.REACT_APP_APP_URL}/api/v1/alt_warehouse_balances/?token=${this.props.token}&nomenclature_id=${res.id}&warehouse_id=${this.state.current_warehouse.id}`
          )
            .then((response) => response.json())
            .then((body) => {
              if (body.result.length > 0) {
                this.formRefNom.current.setFieldsValue({
                  count: 1,
                });
                console.log(body.result.current_amount);
                this.setState({
                  nomenclature_min: 1,
                  nomenclature_max: body.result[0].current_amount,
                  addNomButtonDisabled: false,
                });
              } else {
                this.formRefNom.current.setFieldsValue({
                  count: 0,
                });
                this.setState({
                  nomenclature_min: 0,
                  nomenclature_max: 0,
                  addNomButtonDisabled: false,
                });
              }
            });
        }
      } else {
        this.formRefNom.current.setFieldsValue({
          count: 1,
        });
        this.setState({
          nomenclature_min: 1,
          nomenclature_max: null,
          addNomButtonDisabled: false,
        });
      }

      axios
        .get(
          `https://${process.env.REACT_APP_APP_URL}/api/v1/alt_prices/${res.id}/`,
          {
            params: { token: this.props.token },
          }
        )
        .then((response) => {
          this.setState((prev) => ({
            ...prev,
            current_nomenclature_price: response.data.price,
          }));
        })
        .catch((err) => this.setState({ current_nomenclature_price: null }));

      this.setState({ nomId: res.id }, () => {
        if (this.formRefNom.current) {
          this.formRefNom.current.submit();
        }
      });
    });
  };

  changeCount = (val) => {
    let value = this.formRefNom.current.getFieldValue("count");
    let nextVal = 0;

    if (val === "plus") {
      nextVal = value + 1;
    } else {
      nextVal = value - 1;
    }

    //if (nextVal >= this.state.nomenclature_min && nextVal <= this.state.nomenclature_max) {
    if (nextVal >= 0) {
      this.formRefNom.current.setFieldValue("count", nextVal);
    }
  };

  changeDiscount = (val) => {
    if (val === "percent") {
      this.setState({ discount_type: "percent" });
    }

    if (val === "rubles") {
      this.setState({ discount_type: "rubles" });
    }
  };

  addNomenclature = async (values) => {
    this.setState({ isRequestLoading: true });
    fetch(
      `https://${process.env.REACT_APP_APP_URL}/api/v1/alt_prices/${this.state.nomId}/?token=${this.props.token}`
    )
      .then((response) => response.json())
      .then(async (body) => {
        this.setState({ isRequestLoading: false });
        const count = parseFloat(values.count);
        const amount = body.price ? body.price : 0;
        let item = {
          id: this.state.nomId,
          name: values.nomenclature,
          amount: this.state.current_nomenclature_price?.toFixed(2) || 0,
          discount: 0,
          count: 1,
          unit: this.state.current_nomenclature.unit_name,
          final_amount: (amount * count).toFixed(2),
        };

        if (values.discount > 0) {
          if (this.state.discount_type === "percent") {
            const amount = parseFloat(item.amount);
            const onePerc = amount / 100;
            const discount = onePerc * values.discount;

            item.discount = discount.toFixed(2);
            item.final_amount = (item.final_amount - item.discount).toFixed(2);
          } else {
            item.discount = values.discount.toFixed(2);
            item.final_amount = (item.final_amount - item.discount).toFixed(2);
          }
        }

        const DS = [...this.state.nomDS];
        const index = this.state.nomDS.findIndex(
          (item) => this.state.nomId === item.id
        );

        if (index !== -1) {
          const item = DS[index];
          //if (item.count < this.state.nomenclature_max) {
          console.log(values);
          console.log(item.count);
          item.count = parseInt(item.count + values.count);

          const itemCount = parseFloat(item.count);
          const itemAmount = parseFloat(item.amount);
          const itemDiscount = parseFloat(item.discount);

          item.final_amount = (itemCount * itemAmount - itemDiscount).toFixed(
            2
          );
          this.setState(
            (prev) => ({ ...prev, nomDS: DS, isRequestLoading: false }),
            () => this.raschet(DS)
          );
          //else {
          //    message.error("На складе нет такого количества")
          //}
        } else {
          DS.unshift(item);
          this.setState(
            (prev) => ({ ...prev, nomDS: DS, isRequestLoading: false }),
            () => this.raschet(DS)
          );
        }
      })
      .catch((e) => {
        this.setState({ isRequestLoading: false });
      })
      .finally(() => console.log("4", this.state.nomDS));
  };

  requestOrganizations = async (id) => {
    await apiRequests.organizations
      .getItems()
      .then((res) => {
        this.setState({
          orgData: res.data.result.map((user) => ({
            label: `${user.short_name}`,
            value: user.id,
          })),
        });
      })
      .then(() => {
        this.setState({
          current_organization: id,
          isLoading: false,
        });
      });
  };

  showModal = async () => {
    console.log(this.props.doc);
    try {
      this.setState({ isLoading: true });

      const res = await this.findDocSales(this.props.doc.id);

      this.setState({
        isInitialization: true,
        settings: res.settings,
      });

      console.log(res.contragent);
      this.onSelectCa(res.contragent);
      this.onSelectContract(res.contract);
      this.onSelectWareHouse(res.warehouse);

      let nomDS = [];

      res.goods.forEach(function (arrayItem) {
        let item = {
          id: arrayItem.nomenclature,
          name: arrayItem.nomenclature_name,
          amount: arrayItem.price.toFixed(2),
          discount: arrayItem.discount,
          count: arrayItem.quantity,
          unit: arrayItem.unit_name,
          final_amount: (
            parseFloat(arrayItem.price) * parseFloat(arrayItem.quantity)
          ).toFixed(2),
        };
        nomDS.push(item);
      });

      console.log(res);

      // Используем setState с коллбеком, чтобы гарантировать, что состояние установлено до следующего шага
      this.setState(
        (prev) => ({
          ...prev,
          nomDS: nomDS,
          current_organization: res.organization,
          isLoading: false,
        }),
        () => {
          this.raschet(nomDS);
        }
      );

      // Ставим isModalVisible в true после установки всех значений
      this.setState({ isModalVisible: true });
    } catch (error) {
      console.error(error);
      // Обработка ошибок (если нужно)
      this.setState({ isLoading: false });
    }
  };

  finish = (action) => {
    const { nomDS, date_next_created } = this.state;

    let body = {
      number: this.formRef.current.getFieldValue("number"),
      dated: Math.floor(Date.now() / 1000),

      operation: "Заказ",
      comment: this.formRef.current.getFieldValue("comment"),
      tax_included: true,
      tax_active: true,
      goods: [],
      settings: {
        ...this.formRefSettings.current?.getFieldsValue([
          "repeatability_period",
          "repeatability_value",
          "repeatability_count",
          "transfer_from_weekends",
          "skip_current_month",
          "default_payment_status",
          "repeatability_tags",
          "date_next_created",
          "repeatability_status",
        ]),
        date_next_created: date_next_created,
      },
    };

    if (this.props.tags !== undefined) {
      body.tags = this.props.tags;
    }

    if (this.state.loyality_card) {
      body.loyality_card_id = this.state.loyality_card.id;
    }

    if (!this.state.current_warehouse || !this.state.current_organization) {
      message.error("Вы не выбрали склад или организацию!");
    } else {
      body.warehouse = this.state.current_warehouse.id;
      if (this.state.current_contragent) {
        body.contragent = this.state.current_contragent.id;
      }
      if (this.state.current_contract) {
        body.contract = this.state.current_contract.id;
      }
      if (this.state.current_organization) {
        body.organization = this.state.current_organization;
      }

      nomDS.map((item) => {
        let good_body = {
          price: parseFloat(item.amount),
          quantity: parseFloat(item.count),
          unit: 116,
          discount: parseFloat(item.discount),
          sum_discounted: parseFloat(item.discount),
          nomenclature: item.id,
        };
        body.goods.push(good_body);
        return 0;
      });

      body.paid_rubles = this.finRef.current.getFieldValue("rubles");
      body.paid_lt = this.finRef.current.getFieldValue("lt");
      body.id = this.props.doc.id;

      apiRequests.docs
        .patchSales(this.props.doc.id, [body])
        .then((response) => {
          message.success("Вы успешно изменили документ");
          this.setState({ isModalVisible: false });
        });
    }
  };

  handleState = () => {
    this.setState({
      isModalVisible: false,
      isInitialization: false,
      current_contragent: null,
      changeData: false,
      discount_type: "percent",
      loyality_balance: 0,
      nomenclature_min: 0,
      nomenclature_max: 0,
      amount_without_discount: 0,
      amount_with_discount: 0,
      amount_discount: 0,
      nomDS: [],
      settings: {},
      date_next_created: null,
    });
  };

  handleCancel = () => {
    this.state.changeData === true
      ? Modal.confirm({
          title: "Подтвердите действие",
          content: "Вы дейтвительно хотите закрыть окно?",
          okText: "Да",
          cancelText: "Нет",
          onOk: () => {
            this.handleState();
          },
        })
      : this.handleState();
  };

  onValuesChange = (changedValues, allValues) => {
    this.setState({ changeData: true });
  };

  setFinishPrices = (total) => {
    if (this.state.loyality_card) {
      const loyalityBalance = this.state.loyality_card.balance;
      const maxDiscount = this.state.loyality_card.max_percentage;
      const availableDiscount = (loyalityBalance / 100) * maxDiscount;
      const rublesWithDiscount = total - availableDiscount;
      if (rublesWithDiscount <= 0) {
        const rubles = 0;
        const loyality = availableDiscount + rublesWithDiscount;
        this.finRef.current.setFieldValue("lt", loyality);
        this.finRef.current.setFieldValue("rubles", rubles);
        this.setState({
          max_paid_loyality: availableDiscount,
        });
        return null;
      }
      if (this.state.isInitialization) {
        if (this.finRef.current) {
          this.finRef.current.setFieldValue("lt", this.props.doc.paid_loyality);
          this.finRef.current.setFieldValue(
            "rubles",
            this.props.doc.paid_rubles
          );
        }
      } else {
        this.finRef.current.setFieldValue("lt", availableDiscount);
        this.finRef.current.setFieldValue("rubles", rublesWithDiscount);
      }

      this.setState({
        max_paid_loyality: availableDiscount,
        isInitialization: false,
      });
    }
  };

  onRecalculateLoyality = (value) => {
    if (this.state.loyality_card) {
      const total = this.state.amount_without_discount;
      const diff = Math.abs(total - value);
      this.finRef.current.setFieldValue("lt", diff);
    }
  };

  onRecalculateRubles = (value) => {
    const total = this.state.amount_without_discount;
    const diff = Math.abs(total - value);
    this.finRef.current.setFieldValue("rubles", diff);
  };

  createLoyalityCard = async () => {
    await apiRequests.loyalitySettings
      .getItems({
        token: this.props.token,
        organization_id: this.state.current_organization.id,
      })
      .then((res) => {
        return res.data.result[0];
      })
      .then((organization) => {
        const body = {
          card_number: this.state.current_contragent.phone,
          tags: "",
          phone_number: this.state.current_contragent.phone,
          contragent_id: this.state.current_contragent.id,
          contragent_name: this.state.current_contragent.name,
          organization_id: this.state.current_organization.id,
          lifetime: organization.lifetime,
          status_card: true,
          is_deleted: false,
        };
        apiRequests.loyalityCards
          .post([body])
          .then((res) => {
            return res.data[0].id;
          })
          .then((cardId) => {
            apiRequests.loyalityCards.getById(cardId).then((res) => {
              const loyalityBalance = res.data.balance.toFixed(2);
              this.setState(
                {
                  loyality_balance: loyalityBalance,
                  loyality_card: res.data,
                },
                () => this.setFinishPrices(this.state.amount_with_discount)
              );
            });
          });
      });
  };

  render() {
    const { nomDS } = this.state;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    return (
      <>
        <Button
          style={{ marginRight: 10 }}
          icon={<EditOutlined />}
          onClick={this.showModal}
        />
        <Modal
          width={1800}
          destroyOnClose={true}
          footer={null}
          centered
          title={[
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Проведение документа продажи
              <Button.Group>
                <Popover
                  title="Настройки"
                  trigger="click"
                  content={
                    <Form
                      name="basic"
                      ref={this.formRefSettings}
                      onValuesChange={this.onValuesChange}
                      initialValues={this.state.settings}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "4px",
                        }}
                      >
                        <Form.Item
                          name="repeatability_period"
                          style={{ flex: 2 }}
                        >
                          <Select
                            placeholder="Период"
                            options={[
                              { value: "minutes", label: "Минуты" },
                              { value: "hours", label: "Часы" },
                              { value: "days", label: "Дни" },
                              { value: "weeks", label: "Недели" },
                              { value: "months", label: "Месяцы" },
                            ]}
                          />
                        </Form.Item>
                        <Form.Item
                          name="repeatability_value"
                          style={{ flex: 1 }}
                        >
                          <Input type="number" placeholder="value" />
                        </Form.Item>
                        <Form.Item
                          name="repeatability_count"
                          style={{ flex: 1 }}
                        >
                          <Input type="number" placeholder="count" />
                        </Form.Item>
                      </div>

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ textAlign: "end" }}>
                            <Form.Item
                              label="Переносить с выходных"
                              name="transfer_from_weekends"
                              valuePropName="checked"
                              style={{ margin: 0 }}
                            >
                              <Checkbox />
                            </Form.Item>
                            <Form.Item
                              label="Пропустить текущий месяц"
                              name="skip_current_month"
                              valuePropName="checked"
                              style={{ margin: 0 }}
                            >
                              <Checkbox />
                            </Form.Item>
                          </div>
                          <div style={{ textAlign: "end" }}>
                            <Form.Item
                              label="Проводить платежи"
                              name="default_payment_status"
                              valuePropName="checked"
                              style={{ margin: 0 }}
                            >
                              <Checkbox />
                            </Form.Item>
                            <Form.Item
                              label="Повторить теги"
                              name="repeatability_tags"
                              valuePropName="checked"
                              style={{ margin: 0 }}
                            >
                              <Checkbox />
                            </Form.Item>
                          </div>
                        </div>
                        <Form.Item
                          label="Включить автоповтор"
                          name="repeatability_status"
                          valuePropName="checked"
                          style={{ margin: "24px 0 0 0" }}
                        >
                          <Checkbox />
                        </Form.Item>
                        <Form.Item
                          name="date_next_created"
                          style={{ margin: "24px 0 0 0" }}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            placeholder="Следующее создание"
                            onChange={(date) =>
                              this.setState({ date_next_created: date.unix() })
                            }
                          />
                        </Form.Item>
                      </div>
                    </Form>
                  }
                >
                  <Button>
                    <SettingOutlined />
                  </Button>
                </Popover>
                <Button onClick={this.handleCancel}>
                  <CloseOutlined />
                </Button>
              </Button.Group>
            </div>,
          ]}
          closeIcon={null}
          open={this.state.isModalVisible}
          onCancel={this.handleCancel}
        >
          <AddBarCode
            visible={this.state.isAddBarCodeVisible}
            close={this.closeAddBarcode}
            barcode={this.state.barcode}
            api={this.api}
            token={this.props.token}
          />
          <BarcodeReader onScan={this.barcodeFind} />
          <Layout>
            <Sider style={siderStyle}>
              <Form
                name="basic"
                ref={this.formRef}
                layout="vertical"
                style={{ marginLeft: 10, marginRight: 10 }}
                initialValues={this.leftBarIV}
                onValuesChange={this.onValuesChange}
                // onFinish={onFinish}
              >
                <Form.Item
                  label="Контрагент"
                  name="contragent"
                  style={{ marginBottom: 12 }}
                >
                  <ContragentAutocomplete
                    forType={"docs_sales"}
                    contragent={this.state.current_contragent}
                    api={this.api}
                    token={this.props.token}
                    warehouse={this.state.current_warehouse}
                    onSelect={this.onSelectCa}
                    width={180}
                  />
                </Form.Item>

                <Form.Item
                  label="Склад отгрузки"
                  name="warehouse"
                  style={{ marginBottom: 12 }}
                >
                  <WareHousesAutocomplete
                    api={this.api}
                    token={this.props.token}
                    onSelect={this.onSelectWareHouse}
                  />
                </Form.Item>
                {this.state.isLoading === false ? (
                  <Form.Item label="Организация" name="organization">
                    <OrganizationAutoComplete
                      defaultValue={this.state.current_organization}
                      api={this.api}
                      token={this.props.token}
                      // organizations={this.state.orgData}
                      onSelect={this.onSelectOrg}
                    />
                  </Form.Item>
                ) : null}
                <DocSalesDrawer
                  onSelectContract={this.onSelectContract}
                  token={this.props.token}
                />
              </Form>
            </Sider>
            <Layout>
              <Header style={headerStyle}>
                <Form
                  name="basic1"
                  ref={this.formRefNom}
                  style={{
                    display: "flex",
                  }}
                  layout="inline"
                  initialValues={{
                    remember: true,
                  }}
                  onValuesChange={this.onValuesChange}
                  onFinish={this.addNomenclature}
                >
                  <Compact style={{ width: "100%" }}>
                    <Form.Item
                      style={{ width: "10%", marginInlineEnd: 0 }}
                      name="nomenclature"
                    >
                      <NomenclatureAutoComplete
                        buttonIntegrated
                        placeholder={"Выберите товар"}
                        api={this.api}
                        token={this.props.token}
                        onSelect={this.onSelectNom}
                      />
                    </Form.Item>
                    <Form.Item
                      name="name_nomeclature"
                      style={{ width: "90%", marginInlineEnd: 0 }}
                    >
                      <NomenclatureSearchField
                        api={this.api}
                        token={this.props.token}
                        select={this.onSelectNom}
                        nomDS={this.state.nomDS}
                      />
                    </Form.Item>
                  </Compact>

                  <Form.Item name="count" initialValue={1} hidden>
                    <InputNumber precision={0} min={0} />
                  </Form.Item>
                </Form>
              </Header>
              <Content style={contentStyle}>
                <Table
                  style={{ marginTop: 10 }}
                  dataSource={require("lodash").cloneDeep(nomDS)}
                  components={components}
                  rowClassName={(record) => record.is_deleted && "disabled-row"}
                  rowKey={(record) => record.id}
                  bordered
                  columns={columns}
                ></Table>
              </Content>
            </Layout>
            <Sider style={siderStyle}>
              <Form
                name="basic2"
                ref={this.finRef}
                layout="vertical"
                style={{ marginLeft: 10, marginRight: 10 }}
              >
                <Form.Item label="Без скидки:" style={{ marginBottom: 12 }}>
                  <Input
                    style={{ color: "black" }}
                    disabled
                    value={this.state.amount_without_discount}
                  />
                </Form.Item>

                <Form.Item label="Скидка:" style={{ marginBottom: 12 }}>
                  <Input
                    style={{ color: "black" }}
                    disabled
                    value={this.state.amount_discount}
                  />
                </Form.Item>

                <Form.Item
                  label="Остаток лояльности:"
                  style={{ marginBottom: 12 }}
                >
                  <Input
                    style={
                      !this.state.loyality_balance
                        ? { color: "orange", borderColor: "orange" }
                        : this.state.loyality_balance === "Нет карты"
                        ? { color: "red", borderColor: "red" }
                        : Number(this.state.loyality_balance) === 0
                        ? { color: "green", borderColor: "green" }
                        : { color: "blue", borderColor: "blue" }
                    }
                    disabled
                    value={
                      !this.state.loyality_balance
                        ? "Не выбран контрагент!"
                        : this.state.loyality_balance === "Нет карты"
                        ? this.state.loyality_balance
                        : Number(this.state.loyality_balance) === 0
                        ? "Нет баллов"
                        : this.state.loyality_balance
                    }
                  />
                  {this.state.loyality_balance === "Нет карты" ? (
                    <Button
                      onClick={this.createLoyalityCard}
                      style={{ width: "100%", marginTop: "10px" }}
                    >
                      Создать карту
                    </Button>
                  ) : null}
                </Form.Item>

                <Form.Item label="Итого:" style={{ marginBottom: 12 }}>
                  <Input
                    style={{ color: "black" }}
                    disabled
                    value={this.state.amount_with_discount}
                  />
                </Form.Item>

                <Form.Item
                  label="Баллами:"
                  name={"lt"}
                  initialValue={this.props.doc.paid_loyality}
                  style={{ marginBottom: 12 }}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    precision={2}
                    min={0}
                    max={this.state.max_paid_loyality}
                    disabled={!this.state.loyality_card}
                    controls={false}
                    onChange={(value) => this.onRecalculateRubles(value)}
                  />
                </Form.Item>

                <Form.Item
                  label="Рублями:"
                  name={"rubles"}
                  initialValue={this.props.doc.paid_rubles}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    precision={2}
                    min={0}
                    max={this.state.amount_with_discount}
                    controls={false}
                    onChange={(value) => this.onRecalculateLoyality(value)}
                  />
                </Form.Item>
              </Form>

              <Button
                onClick={() => this.finish("add_proc")}
                style={{ width: "100%" }}
              >
                Изменить
              </Button>
              {/* <Button onClick={() => this.finish("only_add")} style={{ marginTop: 10 }}>Только создать</Button> */}
            </Sider>
          </Layout>
        </Modal>
      </>
    );
  }
}

export default EditDocsSales;

//страница "Документы продаж"
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Button,
  Table,
  Switch,
  Popconfirm,
  message,
  Space,
  Collapse,
  Select,
  DatePicker,
} from "antd";
import {
  DeleteOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { EditDocsSales, PrintedFormDocs } from "src/features/Modal";
import { apiRequests } from "src/shared/api/api";
import {
  ContragentAutocomplete,
  DebounceSelectFil,
  OrganizationAutoComplete,
  WareHousesAutocomplete,
} from "../../shared";
import CreateDocsSalesWrapper from "../../features/Modal/ui/CreateDocsSalesWrapper";

const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} обязательно для ввода.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

class DocsSales extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.columns = [
      {
        title: "Номер",
        dataIndex: "number",
        key: "number",
        // width: 260,
        editable: false,
      },
      {
        title: "Дата проведения",
        key: "dated",
        dataIndex: "dated",
        // width: 160,
        editable: false,
        render: (date) => {
          const dateFormat = new Date(date * 1000);
          return `
                    ${dateFormat.getDate()}.${
            dateFormat.getMonth() + 1
          }.${dateFormat.getFullYear()}
                    ${dateFormat.getHours()}:${dateFormat.getMinutes()}:${dateFormat.getSeconds()}
                    `;
        },
      },
      {
        title: "Статус",
        key: "status",
        dataIndex: "status",
        // width: 160,
        editable: false,
        render: (checked, row) => {
          return (
            <Switch
              style={{ marginLeft: "13px" }}
              checked={checked}
              onClick={(checked) => this.handleChangeStatus(checked, row)}
            />
          );
        },
      },
      {
        title: "Авто-повторение",
        dataIndex: "autorepeat",
        key: "autorepeat",
        editable: false,
        render: (checked, row) => {
          return (
            <font style={{ color: `${checked ? "green" : "red"}` }}>
              {checked ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
            </font>
          );
        },
      },
      {
        title: "Оплачен полностью",
        key: "full_paid",
        dataIndex: "full_paid",
        // width: 400,
        editable: false,
        render: (checked, row) => {
          if (row.paid_doc >= row.sum - row.doc_discount) {
            return (
              <font style={{ color: "green" }}>
                <CheckCircleOutlined /> Оплачено
              </font>
            );
          }
          return (
            <font style={{ color: "red" }}>
              <CloseCircleOutlined /> Не оплачено
            </font>
          );
        },
      },
      {
        title: "Сумма",
        key: "sum",
        dataIndex: "sum",
        // width: 400,
        editable: false,
      },
    ];

    if (props.amo_widget === undefined || props.amo_widget !== true) {
      this.columns.push(
        {
          title: "Скидка (на чек)",
          key: "doc_discount",
          dataIndex: "doc_discount",
          // width: 400,
          editable: false,
        },
        {
          title: "Товаров (в чеке)",
          key: "nomenclature_count",
          dataIndex: "nomenclature_count",
          // width: 400,
          editable: false,
        },
        {
          title: "Оплачено",
          key: "paid_doc",
          dataIndex: "paid_doc",
          // width: 400,
          editable: false,
        },
        {
          title: "Оплачено бонусами",
          key: "paid_loyality",
          dataIndex: "paid_loyality",
          // width: 400,
          editable: false,
        },
        {
          title: "Оплачено рублями",
          key: "paid_rubles",
          dataIndex: "paid_rubles",
          // width: 400,
          editable: false,
        }
      );
    }

    this.columns.push({
      title: "Действие",
      key: "action",
      // width: 100,
      width: 160,
      render: (_, record) => {
        return this.state.dataSource.length >= 1 ? (
          <>
            <Popconfirm
              title="Подтвердите удаление"
              onConfirm={() => this.handleDelete(record.id)}
              cancelText="Отмена"
              okText="OK"
            >
              <Button icon={<DeleteOutlined />} style={{ marginRight: 10 }} />
            </Popconfirm>
            <EditDocsSales
              doc={record}
              token={this.props.query.token}
              updateRow={this.updateRow}
              tags={this.props.tags}
            />
            <PrintedFormDocs token={this.props.query.token} record={record} />
          </>
        ) : (
          "Загрузка..."
        );
      },
    });

    this.state = {
      count: this.props.c,
      dataSource: this.props.ds,
      loading: true,
      currentPage: 1,
      filters: {},
      filterCollapsed: false,
    };
  }

  componentDidMount() {
    this.fetch(
      1,
      `https://${process.env.REACT_APP_APP_URL}/api/v1/docs_sales/`
    );
    const { websocket } = this.props;

    websocket.onmessage = (message) => {
      const data = JSON.parse(message.data);

      if (data.target === "docs_sales") {
        if (data.action === "create") {
          data.result.forEach((docs_sale) => {
            if (
              this.props.tags === undefined ||
              (this.props.tags !== undefined &&
                docs_sale.tags === this.props.tags)
            ) {
              if (this.state.currentPage === 1) {
                const DS = [...this.state.dataSource];
                const C = this.state.count;
                if (DS.length <= 34) {
                  DS.unshift(docs_sale);
                } else {
                  DS.pop();
                  DS.unshift(docs_sale);
                }
                this.setState({ dataSource: DS, count: C + 1 });
              }
            }
          });
        }

        if (data.action === "edit") {
          data.result.forEach((docs_sale) => {
            const newData = [...this.state.dataSource];
            const index = newData.findIndex((item) => docs_sale.id === item.id);

            if (index !== -1) {
              const item = newData[index];
              newData.splice(index, 1, { ...item, ...docs_sale });
              this.setState({ dataSource: newData });
            }
          });
        }

        if (data.action === "delete") {
          data.result.forEach((docs_sale) => {
            const newData = [...this.state.dataSource];
            const index = newData.findIndex((item) => docs_sale === item.id);

            if (index !== -1) {
              newData.splice(index, 1);
              this.setState({ dataSource: newData });
            }
          });
        }
      }
    };
  }

  handleChangeStatus = (status, row) => {
    this.edit_request(row.id, row, { status: status });
  };

  fetch = (page, url = {}, filters = {}) => {
    const limit = 35;
    const offset = page * 35 - 35;

    let params = {
      token: this.props.query.token,
      limit: limit,
      offset: offset,
      ...filters,
    };

    if (this.props.tags !== undefined) {
      params.tags = this.props.tags;
    }

    axios.get(url, { params: params }).then((response) => {
      const newData = response.data.result.map((rowData) => ({
        created_at: Date.now(),
        updated_at: Date.now(),
        response_code: 200,
        response: JSON.stringify(rowData, null, 3),
        ...rowData,
      }));

      if (response.data.result && response.data.result.length > 0) {
        let return_tags = response.data.result
          .map((doc) => doc.tags?.split(","))
          .flat(1)
          .filter((item, pos, self) => self.indexOf(item) === pos)
          .map((value, i) => {
            return {
              label: value,
              value: i,
            };
          });
        this.setState({ tagsMeta: return_tags });

        let res2 = response.data.result
          .map((doc) => doc.operation)
          .filter((item, pos, self) => self.indexOf(item) === pos)
          .map((value, i) => {
            return {
              label: value,
              value: i,
            };
          });
        this.setState({ operationsMeta: res2 });

        let res3 = response.data.result
          .map((doc) => doc.comment)
          .filter((item, pos, self) => self.indexOf(item) === pos)
          .map((value, i) => {
            return {
              label: value,
              value: i,
            };
          });
        this.setState({ commentsMeta: res3 });
      }

      this.setState({
        count: response.data.count,
        dataSource: newData,
        loading: false,
      });
    });
  };

  handleDelete = (id) => {
    const dataSource = [...this.state.dataSource];
    const row_index = dataSource.findIndex((item) => item.id === id);
    const row = dataSource[row_index];
    dataSource.splice(row_index, 1);

    this.setState({
      dataSource: dataSource,
    });

    axios
      .delete(
        `https://${process.env.REACT_APP_APP_URL}/api/v1/docs_sales/${row.id}/`,
        {
          params: { token: this.props.query.token },
        }
      )
      .then((response) => {
        message.success("Вы успешно удалили документ продажи");
      })
      .catch((err) => {
        message.error("Не удалось удалить документ продажи!");
        console.log("err", err);
      });
  };

  edit_request = (id, payment, row) => {
    let edit_dict = {};

    for (let item in row) {
      if (row[item] !== payment[item]) {
        edit_dict[item] = row[item];
      }
    }

    edit_dict.id = id;
    edit_dict.organization = payment.organization;

    if (Object.keys(edit_dict).length !== 0) {
      apiRequests.docs.patchSales(id, [edit_dict]);
    } else {
      message.error(<>Вы не сделали никаких изменений!</>);
    }
  };

  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];

    newData.splice(index, 1, { ...item, ...row });

    this.setState({
      dataSource: newData,
    });

    this.edit_request(newData.splice(index, 1, { ...item, ...row })[0]);
  };

  filterHandler = async (values) => {
    message.loading({
      content: "Поиск...",
    });
    Object.keys(values).forEach(
      (key) =>
        (values[key] === undefined ||
          values[key] === null ||
          values[key].length === 0) &&
        delete values[key]
    );

    if ("tags" in values) {
      values["tags"] = values.tags
        .map((item) => (item.label ? item.label : item.value))
        .join(",");
    }

    if ("operation" in values) {
      values["operation"] = values.operation
        .map((item) => (item.label ? item.label : item.value))
        .join(",");
    }

    if ("comment" in values) {
      values["comment"] = values.comment
        .map((item) => (item.label ? item.label : item.value))
        .join(",");
    }

    if ("sales_manager" in values) {
      values["sales_manager"] = values["sales_manager"].value;
    }

    if ("created_by" in values) {
      values["created_by"] = values["created_by"].value;
    }

    if ("dated" in values) {
      values["dated_from"] = values["dated"][0].unix();
      values["dated_to"] = values["dated"][1].unix();
      delete values["dated"];
    }

    if ("created_at" in values) {
      values["created_at_from"] = values["created_at"][0].unix();
      values["created_at_to"] = values["created_at"][1].unix();
      delete values["created_at"];
    }

    if ("updated_at" in values) {
      values["updated_at_from"] = values["updated_at"][0].unix();
      values["updated_atd_to"] = values["updated_at"][1].unix();
      delete values["updated_at"];
    }

    this.setState({ filters: values });
    this.fetch(
      1,
      `https://${process.env.REACT_APP_APP_URL}/api/v1/docs_sales/`,
      values
    );
    message.destroy();
    message.success({
      content: "Поиск завершен",
      duration: 1,
    });
  };

  fetchManager = async (name) => {
    return fetch(
      `https://${process.env.REACT_APP_APP_URL}/api/v1/cashbox_users/?token=${this.props.query.token}`
    )
      .then((response) => response.json())
      .then((body) => {
        return body;
      })
      .then((body) => {
        if (body.result) {
          return body.result.map((user) => ({
            label: `${user.first_name} ${user.last_name}`,
            value: user.id,
          }));
        }
      })
      .then((body) => {
        return body;
      });
  };

  fetchTags = async (name) => {
    if (name) {
      return fetch(
        `https://${process.env.REACT_APP_APP_URL}/api/v1/docs_sales/?token=${this.props.query.token}&tags=${name}`
      )
        .then((response) => response.json())
        .then((body) => {
          return body;
        })
        .then((body) => {
          if (body.result && body.result.length > 0) {
            let res = body.result.map((doc) => doc.tags.split(",")).flat(1);
            let tags = res.filter(
              (item) => item.toUpperCase().indexOf(name.toUpperCase()) !== -1
            );
            let return_tags = tags.map((value, i) => {
              return {
                label: value,
                value: i,
              };
            });
            this.setState({ tagsMeta: return_tags });
            return return_tags;
          }
        })
        .then((body) => {
          return body;
        });
    }
  };

  fetchOperations = async (name) => {
    if (name) {
      return fetch(
        `https://${process.env.REACT_APP_APP_URL}/api/v1/docs_sales/?token=${this.props.query.token}&operation=${name}`
      )
        .then((response) => response.json())
        .then((body) => {
          return body;
        })
        .then((body) => {
          if (body.result && body.result.length > 0) {
            let res = body.result
              .map((doc) => doc.operation)
              .filter(
                (item) => item.toUpperCase().indexOf(name.toUpperCase()) !== -1
              )
              .map((value, i) => {
                return {
                  label: value,
                  value: i,
                };
              });
            this.setState({ operationsMeta: res });
            return res;
          }
        })
        .then((body) => {
          return body;
        });
    }
  };

  fetchComments = async (name) => {
    if (name) {
      return fetch(
        `https://${process.env.REACT_APP_APP_URL}/api/v1/docs_sales/?token=${this.props.query.token}&comment=${name}`
      )
        .then((response) => response.json())
        .then((body) => {
          return body;
        })
        .then((body) => {
          if (body.result && body.result.length > 0) {
            let res = body.result
              .map((doc) => doc.comment)
              .filter(
                (item) => item.toUpperCase().indexOf(name.toUpperCase()) !== -1
              )
              .map((value, i) => {
                return {
                  label: value,
                  value: i,
                };
              });
            this.setState({ commentsMeta: res });
            return res;
          }
        })
        .then((body) => {
          return body;
        });
    }
  };

  updateRow = (data, response) => {
    const id = data.id || response.data.id;

    const newData = this.state.dataSource;

    newData.forEach((row) => {
      if (row.id === id) {
        row.response_code = response.status;
        row.response = JSON.stringify(response.data, null, 3);
      }
    });

    this.setState({
      dataSource: newData,
    });
  };

  findContragent = async (id) => {
    return fetch(
      `https://${process.env.REACT_APP_APP_URL}/api/v1/contragents/${id}/?token=${this.props.query.token}`
    )
      .then((response) => response.json())
      .then((body) => {
        return body;
      });
  };

  onSelectCa = (val) => {
    this.findContragent(val).then((res) => {
      this.setState({ current_contragent: res });
      this.formRef.current.setFieldsValue({
        contragent: res.id,
      });
    });
  };

  render() {
    const { dataSource } = this.state;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    return (
      <div>
        <div style={{ marginBottom: 10, display: "flex", gridGap: "8px" }}>
          <CreateDocsSalesWrapper
            token={this.props.query.token}
            updateRow={this.updateRow}
            tags={this.props.tags}
            phone={this.props.phone}
            name={this.props.name}
          />
          {/*<CreateDocsSales*/}
          {/*    // meta={this.props.meta}*/}
          {/*    token={this.props.query.token}*/}
          {/*    updateRow={this.updateRow}*/}
          {/*    tags={this.props.tags}*/}
          {/*    phone={this.props.phone}*/}
          {/*    name={this.props.name}*/}
          {/*/>*/}
          {/*</CreateDocsSalesWrapper>*/}

          <Collapse
            onChange={(arr) => {
              if (arr.length === 0) {
                this.setState({ filterCollapsed: false });
              } else this.setState({ filterCollapsed: true });
            }}
            ghost
            style={{ marginBottom: 15, marginTop: -5 }}
          >
            <Panel header="Фильтры поиска продаж..." key="1">
              <Form
                ref={this.formRef}
                layout={"inline"}
                style={{ marginBottom: 10 }}
                onFinish={this.filterHandler}
              >
                <Space style={{ width: "100%" }}>
                  <Form.Item name="tags">
                    <Select
                      style={{ width: 250 }}
                      options={this.state.tagsMeta}
                      placeholder="Введите теги"
                      allowClear={true}
                      showSearch
                      labelInValue
                      filterOption={false}
                      mode={"tags"}
                      onSearch={this.fetchTags}
                    />
                  </Form.Item>
                  <Form.Item name="operation">
                    <Select
                      style={{ width: 250 }}
                      options={this.state.operationsMeta}
                      placeholder="Введите тип"
                      allowClear={true}
                      showSearch
                      labelInValue
                      filterOption={false}
                      mode={"tags"}
                      onSearch={this.fetchOperations}
                    />
                  </Form.Item>
                  <Form.Item name="comment">
                    <Select
                      style={{ width: 250 }}
                      options={this.state.commentsMeta}
                      placeholder="Введите комментарий"
                      allowClear={true}
                      showSearch
                      labelInValue
                      filterOption={false}
                      mode={"tags"}
                      onSearch={this.fetchComments}
                    />
                  </Form.Item>
                  <Form.Item name="contragent">
                    <ContragentAutocomplete
                      api={`https://${process.env.REACT_APP_APP_URL}/api/v1/`}
                      token={this.props.query.token}
                      placeholder="Введите контрагента"
                      style={{ marginTop: 10 }}
                      allowClear={true}
                      width={250}
                      onSelect={this.onSelectCa}
                      сontragent={this.state.current_contragent}
                    />
                  </Form.Item>
                </Space>
                <Space style={{ width: "100%", marginTop: 10 }}>
                  <Form.Item name="organization">
                    <OrganizationAutoComplete
                      api={`https://${process.env.REACT_APP_APP_URL}/api/v1/`}
                      token={this.props.query.token}
                      labelInValue
                      placeholder="Введите организацию"
                      nodefaultvalue={"true"}
                      style={{ width: 250 }}
                      allowClear={true}
                      onSelect={(obj) =>
                        this.formRef.current.setFieldValue(
                          "organization",
                          obj.value
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item name="warehouse">
                    <WareHousesAutocomplete
                      api={`https://${process.env.REACT_APP_APP_URL}/api/v1/`}
                      token={this.props.query.token}
                      labelInValue
                      placeholder="Введите cклад"
                      style={{ width: 250 }}
                      nodefaultvalue={"true"}
                      allowClear={true}
                      onSelect={(obj) =>
                        this.formRef.current.setFieldValue(
                          "warehouse",
                          obj.value
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item name="sales_manager">
                    <DebounceSelectFil
                      placeholder="Введите имя менеджера"
                      fetchOptions={this.fetchManager}
                      removeIcon={null}
                      style={{
                        width: 250,
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="created_by">
                    <DebounceSelectFil
                      placeholder="Введите имя cоздателя"
                      fetchOptions={this.fetchManager}
                      removeIcon={null}
                      style={{
                        width: 250,
                      }}
                    />
                  </Form.Item>
                </Space>
                <Space style={{ width: "100%" }}>
                  <Form.Item name="dated">
                    <RangePicker
                      style={{ width: 300, marginTop: 10 }}
                      placeholder={["Начальная дата", "Конечная дата"]}
                      allowEmpty={[false, false]}
                      allowClear={true}
                      format="DD.MM.YY"
                    />
                  </Form.Item>
                  <Form.Item name="created_at">
                    <RangePicker
                      style={{ width: 300, marginTop: 10 }}
                      placeholder={["Создан от", "Создан до"]}
                      allowEmpty={[false, false]}
                      allowClear={true}
                      format="DD.MM.YY"
                    />
                  </Form.Item>
                  <Form.Item name="updated_at">
                    <RangePicker
                      style={{ width: 300, marginTop: 10 }}
                      placeholder={["Обновлен от", "Обновлен до"]}
                      allowEmpty={[false, false]}
                      allowClear={true}
                      format="DD.MM.YY"
                    />
                  </Form.Item>
                  <Button
                    style={{ marginTop: 10 }}
                    htmlType="submit"
                    icon={<SearchOutlined />}
                  >
                    Поиск
                  </Button>
                </Space>
              </Form>
            </Panel>
          </Collapse>
        </div>

        <Table
          components={components}
          rowClassName={(record) => record.is_deleted && "disabled-row"}
          rowKey={(record) => record.id}
          bordered
          // scroll={{
          //     y: 600,
          //     x: '85vw',
          // }}
          loading={this.state.loading}
          dataSource={dataSource}
          columns={columns}
          pagination={{
            total: this.state.count,
            onChange: (page) => {
              this.setState(
                { currentPage: page, loading: true },
                this.fetch(
                  page,
                  `https://${process.env.REACT_APP_APP_URL}/api/v1/docs_sales/`
                )
              );
            },
            pageSize: 35,
            showSizeChanger: false,
          }}
        />
      </div>
    );
  }
}

export default DocsSales;

import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Collapse, DatePicker, Form, Input, Select, Space } from "antd";
import { useCallback, useMemo, useState } from "react";
import { DebounceSelect } from "src/shared";
import "vis-timeline/styles/vis-timeline-graph2d.min.css";

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const FilterRent = (props) => {
  const [formVisible, setFormVisible] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const [formFilter] = Form.useForm();

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length > 0) {
      const [startDate, endDate] = dates;
      if (startDate && !endDate) {
        // Если только начальная дата
        formFilter.setFieldValue("date_range", [startDate, null]);
      } else if (!startDate && endDate) {
        // Если только конечная дата
        formFilter.setFieldValue("date_range", [null, endDate]);
      } else {
        // Если обе даты
        formFilter.setFieldValue("date_range", [startDate, endDate]);
      }
    } else {
      formFilter.setFieldValue("date_range", []);
    }
  };

  const handleFilter = () => {
    if (formFilter) {
      const filterData = formFilter.getFieldValue();
      const dates = formFilter.getFieldValue("date_range");
      const startBooking =
        dates && dates[0]
          ? Math.floor(new Date(dates[0]).getTime() / 1000)
          : null;
      const endBooking =
        dates && dates[1]
          ? Math.floor(new Date(dates[1]).getTime() / 1000)
          : null;

      filterData.start_booking = startBooking;
      filterData.end_booking = endBooking;
      delete filterData.date_range;

      props.getData(filterData);
      setIsFiltered(true);
    }
  };

  //   const fetchNomenclature = useCallback(
  //     async (name) => {
  //       return fetch(
  //         `https://${process.env.REACT_APP_APP_URL}/api/v1/nomenclature/?token=${props.token}&name=${name}`
  //       )
  //         .then((response) => response.json())
  //         .then((body) => {
  //           if (body.result) {
  //             return body.result.map((item) => ({
  //               label: `${item.name}`,
  //               value: item.id,
  //             }));
  //           }
  //           return [];
  //         });
  //     },
  //     [props.token]
  //   );

  const fetchContragents = useCallback(
    async (name) => {
      return fetch(
        `https://${process.env.REACT_APP_APP_URL}/api/v1/contragents/?token=${props.token}&name=${name}`
      )
        .then((response) => response.json())
        .then((body) => {
          if (body.result) {
            return body.result.map((item) => ({
              label: `${item.name}`,
              value: item.id,
            }));
          }
          return [];
        });
    },
    [props.token]
  );

  const memoizedFetchContragents = useMemo(() => {
    const cache = new Map();

    return async (name) => {
      if (cache.has(name)) {
        return cache.get(name);
      }
      const result = await fetchContragents(name);
      cache.set(name, result);
      return result;
    };
  }, [fetchContragents]);

  //   const memoizedFetchNomenclature = useMemo(() => {
  //     const cache = new Map();

  //     return async (name) => {
  //       if (cache.has(name)) {
  //         return cache.get(name);
  //       }
  //       const result = await fetchNomenclature(name);
  //       cache.set(name, result);
  //       return result;
  //     };
  //   }, [fetchNomenclature]);

  return (
    <Collapse ghost onChange={() => setFormVisible(!formVisible)}>
      <Panel header="Фильтр поиска заявок" key="1">
        {formVisible && (
          <Form form={formFilter} layout={"inline"}>
            <Space style={{ marginBottom: 10 }}>
              <Form.Item name="title">
                <Input placeholder="Наименование брони" allowClear />
              </Form.Item>
              {/* <Form.Item name="name_nom" style={{ maxWidth: 300 }}>
                            <DebounceSelect
                              style={{ width: 200 }}
                              showSearch
                              labelInValue
                              allowClear="true"
                              placeholder="Наименование номенклатуры"
                              fetchOptions={memoizedFetchNomenclature}
                              onChange={(data) =>
                                formFilter.setFieldValue("name_nom", data.key)
                              }
                            />
                          </Form.Item> */}
              {/* <Form.Item
                            style={{ width: 200 }}
                            name="category_nomenclature"
                          >
                            <Select
                              placeholder="Категория номенклатуры"
                              options={[
                                {
                                  value: "service",
                                  label: "Услуга",
                                },
                                {
                                  value: "product",
                                  label: "Товар",
                                },
                              ]}
                            />
                          </Form.Item> */}
              <Form.Item style={{ width: 200 }} name="client">
                <DebounceSelect
                  showSearch
                  labelInValue
                  allowClear="true"
                  placeholder="Имя клиента"
                  fetchOptions={memoizedFetchContragents}
                  onChange={(data) =>
                    formFilter.setFieldValue("contragent", data.key)
                  }
                />
              </Form.Item>
              <Form.Item name="date_range">
                <RangePicker
                  onCalendarChange={handleDateRangeChange}
                  format="DD.MM.YYYY"
                />
              </Form.Item>
              <Form.Item name="status_doc_sales" style={{ width: 150 }}>
                <Select
                  placeholder="Статус оплаты"
                  options={[
                    { value: "Оплачен", label: "Оплачен" },
                    {
                      value: "Частично оплачен",
                      label: "Частично оплачен",
                    },
                    { value: "Не оплачен", label: "Не оплачен" },
                  ]}
                />
              </Form.Item>
              <Form.Item name={"status_booking"} style={{ width: 150 }}>
                <Select
                  placeholder="Статус брони"
                  options={[
                    { value: "Новый", label: "Новый" },
                    { value: "Подтвержден", label: "Подтвержден" },
                    { value: "Оплачен", label: "Оплачен" },
                    { value: "Забран", label: "Забран" },
                    { value: "Доставлен", label: "Доставлен" },
                    { value: "Выгружен", label: "Выгружен" },
                    {
                      value: "Пролонгирован",
                      label: "Пролонгирован",
                    },
                    { value: "Завершен", label: "Завершен" },
                  ]}
                />
              </Form.Item>
            </Space>
            {/* <Space> */}
            {/* <Form.Item name="status_dept" style={{ width: 150 }}>
                            <Select
                              placeholder="Статус задолженности"
                              options={[
                                { value: "dept", label: "Должен" },
                                { value: "not_dept", label: "Не должен" },
                              ]}
                            />
                          </Form.Item>
                          <Form.Item name="date_create">
                            <DatePicker placeholder="Дата создания" />
                          </Form.Item>
                          <Form.Item name="date_payment">
                            <DatePicker placeholder="Дата оплаты" />
                          </Form.Item>
                          <Form.Item
                            name="tags_nomenclature"
                            style={{ width: 200 }}
                          >
                            <DebounceSelect
                              showSearch
                              labelInValue
                              allowClear="true"
                              placeholder="Теги номенклатуры"
                              fetchOptions={memoizedFetchNomenclature}
                              onChange={(data) =>
                                formFilter.setFieldValue(
                                  "tags_nomenclature",
                                  data.key
                                )
                              }
                            />
                          </Form.Item> */}
            <Button
              icon={<DeleteOutlined />}
              onClick={() => {
                formFilter.resetFields();
                props.getData();
                setIsFiltered(false);
              }}
              danger
              disabled={!isFiltered}
              style={{ marginRight: 10 }}
            >
              Сбросить
            </Button>
            <Form.Item>
              <Button
                disabled={isFiltered}
                icon={<SearchOutlined />}
                onClick={() => handleFilter()}
              >
                Поиск
              </Button>
            </Form.Item>
            {/* </Space> */}
          </Form>
        )}
      </Panel>
    </Collapse>
  );
};

export default FilterRent;

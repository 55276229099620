import { Select, Spin, message, AutoComplete } from "antd";
import { useEffect, useState } from "react";
import AddContragentDrawer from "../../features/Modal/ui/AddContragentDrawer";
import EditContragentDrawer from "../../features/Modal/ui/EditContragentDrawer";
import debounce from "../lib/utils/debounce";
import { apiRequests } from "./api";
import axios from "axios";

const ContragentAutocomplete = (props) => {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [checking, setChecking] = useState(false);
  const [rendering, setRendering] = useState(false);

  const handleSearch = async (value) => {
    let result = [];
    setFetching(true);

    try {
      if (!value) {
        result = await apiRequests.contragents.getItems();
        message.success("Показаны все контрагенты");
      } else if (!isNaN(Number(value))) {
        message.loading("Поиск по ИНН...", 0);
        const innPromise = apiRequests.contragents.getItems({ inn: value });
        message.loading("Поиск по телефону...", 0);
        const phonePromise = apiRequests.contragents.getItems({ phone: value });

        const [innResult, phoneResult] = await Promise.all([
          innPromise,
          phonePromise,
        ]);

        const innData = innResult.data.result || [];
        const phoneData = phoneResult.data.result || [];

        message.destroy();
        if (innData.length > 0) {
          message.success("Найдены совпадения по ИНН");
        } else {
          message.info("Совпадений по ИНН не найдено");
        }

        if (phoneData.length > 0) {
          message.success("Найдены совпадения по телефону");
        } else {
          message.info("Совпадений по телефону не найдено");
        }

        const combinedData = [
          ...new Map(
            [...innData, ...phoneData].map((item) => [item.id, item])
          ).values(),
        ];

        result = { data: { result: combinedData } };
      } else {
        message.loading("Поиск по имени...", 0);
        result = await apiRequests.contragents.getItems({ name: value });

        message.destroy();
        if (result.data.result.length > 0) {
          message.success("Найдены совпадения по имени");
        } else {
          message.info("Совпадений по имени не найдено");
        }
      }

      const options = result.data.result.map((contragent) => ({
        label: contragent.name,
        value: contragent.id,
      }));
      console.log(options);
      setOptions(options);
      setFetching(false);
    } catch (error) {
      message.error("Произошла ошибка при выполнении поиска");
    }
  };

  const SearchHandler = debounce(handleSearch, 650);

  const insertContragent = (value) => {
    const contragent = {
      key: value.id,
      label: `${value.name}`,
      value: `${value.name}`,
    };
    props.onSelect(value.id);
    const newOptions = [...options].filter((i) => i.value !== contragent.key);
    setOptions([contragent, ...newOptions]);
    setValue(contragent);
  };

  const handleClear = () => {
    console.log("clear");
    setValue(null);
    props.onSelect(null);
  };

  useEffect(() => {
    if (props.contragent) {
      if (!checking) handleSearch("");
      setChecking(true);
      setValue({
        key: props.contragent.id,
        label: props.contragent.name,
        value: props.contragent.name,
      });
    } else {
      setValue(null);
    }
    setRendering(true);
  }, [props.contragent, checking]);

  const findContragent = async (id) => {
    axios
      .get(
        `https://${process.env.REACT_APP_APP_URL}/api/v1/contragents/${id}/?token=${props.token}`
      )
      .then((response) => {
        setValue({
          label: response.data.name,
          value: response.data.name,
        });
      });
  };

  return (
    <>
      {props.forType === "docs_sales" ? (
        <>
          <AutoComplete
            {...props}
            mode=""
            allowClear
            onFocus={() => {
              if (!checking) {
                handleSearch("");
                setChecking(true);
              }
            }}
            onClear={handleClear}
            options={options}
            value={value}
            onChange={(val) => setValue(val)}
            onSelect={(val) => {
              props.onSelect(val);
              findContragent(val);
            }}
            onSearch={SearchHandler}
            style={{ width: props.width ? props.width : 300 }}
          />
          {rendering &&
            props.contragent &&
            value &&
            value?.value === props.contragent.name && (
              <EditContragentDrawer
                token={props.token}
                abra={props.contragent.id}
                contragent={value}
                updateField={insertContragent}
              />
            )}
          {rendering && !options.length && value && (
            <AddContragentDrawer
              insertContragent={insertContragent}
              value={value}
              token={props.token}
            />
          )}
        </>
      ) : (
        <Select
          {...props}
          placeholder="Введите контрагента"
          onSearch={SearchHandler}
          onSelect={(val) => {
            // props.onSelectRent(record.label)
            props.onSelect(val);
            findContragent(val);
          }}
          onFocus={() => {
            if (!checking) {
              handleSearch("");
              setChecking(true);
            }
          }}
          showSearch
          suffixIcon={null}
          allowClear
          filterOption={false}
          onClear={handleClear}
          onChange={(val) => {
            setValue(val);
          }}
          style={{ width: props.width ? props.width : 300 }}
          options={options}
          value={value}
          notFoundContent={
            fetching ? (
              <Spin size="small" />
            ) : (
              <div style={{ textAlign: "center" }}>
                При поиске контрагента по <br />
                <span style={{ fontWeight: "bold", letterSpacing: 0.5 }}>
                  Имени / ИНН / Телефону <br />
                </span>
                ничего не найдено.
              </div>
            )
          }
        />
      )}
    </>
  );
};

export default ContragentAutocomplete;
